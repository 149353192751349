import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./store/AuthProvider";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StoreProvider, { StoreContext } from "./store/StoreProvider";

import HomePage from "pages/home";
import PickemHomePage from "pages/pickem-home";
import LoginPage from "pages/login";
import ConflictListPage from "pages/conflicts/ConflictListPage";
import ConflictPage from "pages/conflict/ConflictPage";
import EventListPage from "pages/events/EventListPage";
import EventPage from "pages/event/EventPage";
import EventByIdPage from "pages/event/EventByIdPage";
import EventComparePage from "pages/event/EventComparePage";
import LeaguePage from "pages/league/LeaguePage";
import LeagueListPage from "pages/leagues/LeagueListPage";
import TeamPage from "pages/team/TeamPage";
import TeamComparePage from "pages/team/TeamComparePage";
import TeamListPage from "pages/teams/TeamListPage";
import TestingPage from "pages/testing/TestingPage";
import UserByIdPage from "pages/user/UserByIdPage";
import UsersPage from "pages/users/UsersPage";
import SelectPathPage from "pages/select";
import PickemPage from "pages/pickem/PickemPage";
import PickemCreatePage from "pages/pickem-create/PickemCreatePage"
import PickemJoinPage from "pages/pickem-join/PickemJoinPage"
import PickemPicksPage from "pages/pickem-picks/PickemPicksPage"
import PickemGroupPicksPage from "pages/pickem-group-picks/PickemGroupPicksPage"
import PickemSchedulePage from "pages/pickem-schedule/PickemSchedulePage"
import PickemStandingsPage from "pages/pickem-standings/PickemStandingsPage"
import PickemSettingsPage from "pages/pickem-settings/PickemSettingsPage"
import PickemAccountPage from "pages/pickem-account/PickemAccountPage"

import LoadingPage from "pages/loading";

import { SideMenu } from "./components/Menu/SideMenu";
import { PickemSideMenu } from "./components/Menu/PickemSideMenu";
import { MobileHeader } from "./components/Headers/MobileHeader";
import { FlashMessage } from "./components/common/Popups/FlashMessage";
import OverlaySpinner from "./components/common/Popups/OverlaySpinner";
import { Helmet } from "react-helmet";
import { getUserConfirmation } from "components/common/Form/UnsavedChangesDialog";

/**
 * App Wrap
 * 
 */
const AppWrap = () => {
    const { user, isLoading, accountType } = useContext(AuthContext);
    const [superPath, setSuperPath] = useState(null)

    const superPathSelect = (choice) => {
        console.log("Handled Click from path:", choice)
        window.sessionStorage.setItem("choice", choice);
        setSuperPath(choice)
    }

    useEffect(() => {
        setSuperPath(window.sessionStorage.getItem("choice"));
    }, [])

    const checkAccountType = (acctType) => {
        console.log("AcctType:", acctType)
        switch(acctType) {
            case 'superUser':
                if (superPath && superPath === "pickem") {
                    return (<StoreProvider>
                            <PickemApp />
                        </StoreProvider>)
                }
                else if (superPath && superPath === "dashboard") {
                    return (
                        <StoreProvider>
                            <App />
                        </StoreProvider>)
                }
                else {
                    return <SelectPathPage superPathSelect={superPathSelect} />
                }

            case 'adminUser':
                return (
                    <StoreProvider>
                        <App />
                    </StoreProvider>)
            case 'pickemUser':
                return (<StoreProvider>
                    <PickemApp />
                </StoreProvider>)
            default:
                <LoginPage superPathSelect={superPathSelect} />
                
        }
    }
    return (
        <div className="app-wrap">
            <div className="content-wrap">
                {isLoading ? (
                    <LoadingPage />
                ) : user ? (
                    checkAccountType(accountType)
                ) : (
                    <LoginPage superPathSelect={superPathSelect} />
                )}
            </div>
        </div>
    );
};
export default AppWrap;


/**
 * App
 * 
 */
const App = () => {
    const [isMobileNav, toggleMobileMenu] = useState(false);
    const { meta: { title, description } } = useContext(StoreContext);

    const setMobileMenu = (value = undefined) => {
        toggleMobileMenu(value !== undefined ? value : !isMobileNav);
    };

    return (
        <Router getUserConfirmation={getUserConfirmation}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>

                <MobileHeader
                    toggleMobileNav={setMobileMenu}
                    isMobileNav={isMobileNav}
                />

                <SideMenu
                    isMobileNav={isMobileNav}
                    toggleMobileNav={setMobileMenu}
                />

                <FlashMessage />

                <Switch>
                    {/* conflicts */}
                    <Route
                        path="/conflicts/:conflictId"
                        component={ConflictPage}
                        exact
                    />
                    <Route
                        path="/conflicts"
                        component={ConflictListPage}
                        exact
                    />
                    
                    {/* events */}
                    <Route
                        path="/events/compare"
                        component={EventComparePage}
                        exact
                    />
                    <Route
                        path="/events/search"
                        component={EventByIdPage}
                        exact
                    />
                    <Route
                        path="/events/:eventId"
                        component={EventPage}
                        exact
                    />
                    <Route
                        path="/events"
                        component={EventListPage}
                        exact
                    />

                    {/* leagues */}                    
                    <Route
                        path="/leagues/:leagueId"
                        component={LeaguePage}
                        exact
                    />
                    <Route
                        path="/leagues"
                        component={LeagueListPage}
                        exact
                    />

                    {/* teams */}                    
                    <Route
                        path="/teams/compare"
                        component={TeamComparePage}
                        exact
                    />
                    <Route
                        path="/teams/:teamId"
                        component={TeamPage}
                        exact
                    />
                    <Route
                        path="/teams"
                        component={TeamListPage}
                        exact
                    />
                    {/* Testing */}
                    <Route
                        path="/testing"
                        component={TestingPage}
                        exact
                    />

                    {/* Users */}
                    <Route
                        path="/users"
                        component={UsersPage}
                        exact
                    />
                    <Route
                        path="/users/:userId"
                        component={UserByIdPage}
                        exact
                    />
                    <Route
                        path="/pickem"
                        component={PickemPage}
                        exact
                    />

                    {/* home */}
                    <Route path="" component={HomePage} exact />
                </Switch>
        </Router>
    );
};

/**
 * App
 * 
 */
 const PickemApp = () => {
    const [isMobileNav, toggleMobileMenu] = useState(false);
    const { meta: { title, description } } = useContext(StoreContext);

    const setMobileMenu = (value = undefined) => {
        toggleMobileMenu(value !== undefined ? value : !isMobileNav);
    };

    return (
        <Router getUserConfirmation={getUserConfirmation}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>

                <MobileHeader
                    toggleMobileNav={setMobileMenu}
                    isMobileNav={isMobileNav}
                />

                <PickemSideMenu
                    isMobileNav={isMobileNav}
                    toggleMobileNav={setMobileMenu}
                />

                <FlashMessage />

                <Switch>
                    <Route
                        path="/pickem-create"
                        component={PickemCreatePage}
                        exact
                    />

                    <Route
                        path="/pickem-join"
                        component={PickemJoinPage}
                        exact
                    />

                    <Route
                        path="/pickem-join/:competitionId"
                        component={PickemJoinPage}
                        exact
                    />

                    <Route
                        path="/pickem-picks/:competitionId"
                        component={PickemPicksPage}
                        exact
                    />

                    <Route
                        path="/pickem-group-picks/:competitionId"
                        component={PickemGroupPicksPage}
                        exact
                    />

                    <Route
                        path="/pickem-schedule/:competitionId"
                        component={PickemSchedulePage}
                        exact
                    />

                    <Route
                        path="/pickem-standings/:competitionId"
                        component={PickemStandingsPage}
                        exact
                    />

                    <Route
                        path="/pickem-settings/:competitionId"
                        component={PickemSettingsPage}
                        exact
                    />

                    <Route
                        path="/pickem-account"
                        component={PickemAccountPage}
                        exact
                    />

                    {/*<Route
                        path="pickem-select"
                        component={<SelectPathPage superPathSelect={superPathSelect} />}
                        exact
                    />*/}
                    {/* home */}
                    <Route path="" component={PickemHomePage} exact />
                </Switch>
        </Router>
    );
};
import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
import { PickemTable } from "components/common/Table/PickemTable";
import { headList } from "./model";
//import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import Checkbox from "components/common/Form/Checkbox/Checkbox";
import { Select } from "components/common/Form/Select/Select";
//import { Select } from "components/common/Form/Select/Select";
//import Confirmation from "modals/Confirmation";
import { simpleOptions, options } from "utils/helpers";
import "./PickemPicksPage.scss";
//const CryptoJS = require("crypto-js");

//GetPickset -> know competitor, competition, currentWeek, regular weeks + postseason weeks to build the page

const PickemPicksPage = ({
    history,
    match: {
        params: {
            competitionId
        }

    }
    }) => {
    const { functions, accountData, user } = useContext(AuthContext);
    const { updateMeta, competitionData, updateCompetitionData } = useContext(StoreContext);
    const [userPickset, setUserPickset] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isPickLoading, setIsPickLoading] = useState(true)
    const [currentWeek, setCurrentWeek] = useState(null);
    const [selection, setSelection] = useState({})
    const [isSelectedLoading, setIsSelectedLoading] = useState(true)
    const [initialEventData, setInitialEventData] = useState({})
    const [nameString, setNameString] = useState(null)
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionDescription, setCompetitionDescription] = useState(null)
    const [errorLoadingPicks, setErrorLoadingPicks] = useState(false)
    const [isSavingPicks, setIsSavingPicks] = useState(false)
    const [initialSelection, setInitialSelection] = useState({})
    const [isSaveEnabled, setIsSaveEnabled] = useState(false)

    useEffect(() => {
        updateMeta({
            title: "Picks",
        });
        return clearUser
    }, []);

    useEffect(() => {
        let name = ""
        if (competitionData.name) {
            name = competitionData.name
        }
        else {
            if (accountData.firstName && accountData.lastName) {
                name = `${accountData.firstName} ${accountData.lastName}`
            }
            else if (accountData.firstName) {
                name = `${accountData.firstName}`
            }
            else if (accountData.lastName) {
                name = `${accountData.lastName}`
            }
            else if (user.email) {
                name = `${user.email}`
            }
        }
        console.log("Comp Data:", competitionData)
        setNameString(name)
        setCompetitionDescription(competitionData.competitionDescription)
        setCompetitionName(competitionData.competitionName)
    }, [])

    useEffect(() => {
        getPickset(currentWeek)
    }, [])

    useEffect(() => {
        setIsPickLoading(false)
    }, [userPickset])

    useEffect(() => {
        setIsSelectedLoading(false)
        analyzeUserPickset()
    }, [selection, initialEventData])

    const clearUser = () => {
        updateCompetitionData({picksUserId: user.uid, isRandomUser: false, name: null})
    }

    const getPickset = (weekNumber) => {
        const userId = competitionData.picksUserId ? competitionData.picksUserId : user.uid
        functions.httpsCallable('pickemGetPickset')({
            body: JSON.stringify({
                competitionId: competitionId,
                userId: userId,
                isRandomUser: competitionData.isRandomUser,
                currentWeek: weekNumber
            })
        }).then((result) => {
            setIsLoading(false)
            if (result.data.success) {
                setIsLoading(false)
                setIsSelectedLoading(true)
                addUserPicks({...result.data.data.eventData})
                setInitialEventData(() => ({...result.data.data.eventData}))
                if (!weekNumber) {
                    setCurrentWeek(result.data.data.currentWeek) 
                }
            } else {
                setIsLoading(false)
                setIsSelectedLoading(false)
                setErrorLoadingPicks(true)
            }
        })
    }

    const addUserPicks = (userData) => {
        if (userData.currentWeek) {
            const selectedInfo = {}
            for (const pick of userData.currentWeek) {
                selectedInfo[pick.eventId] = pick.userPick
            }
            setSelection(() => ({...selectedInfo}))
            setInitialSelection(() => ({...selectedInfo}))
        }
        
    }

    const handleRadioChange = (
        value, eventId
    ) => {
        if (Object.keys(selection).length > 0) {
            if (competitionData.isCommissioner === user.uid || competitionData.picksUserId === user.uid) {
                setSelection((prevSelect) => ({
                    ...prevSelect,
                    [eventId]: value
                }))
            }
        }
    };

    const analyzeUserPickset = () => {
        if (initialEventData.currentWeek) {
            let saveEnabled = false
            const userPicksetData = {
                currentWeek: [],
                regWeeks: initialEventData.regWeeks,
                postWeeks: initialEventData.postWeeks
            }
            //console.log("Analyze Picks Current Data:", currentData)
            for (const event of initialEventData.currentWeek) {
                if (!saveEnabled)
                    saveEnabled = selection[event.eventId] !== initialSelection[event.eventId]
                const favoritePicked = (<div><input
                    type="radio"
                    id="favorite"
                    key={`${event.eventId}-favorite`}
                    value={event.favorite}
                    checked={
                        selection[event.eventId] === event.favorite
                    }
                    onChange={() =>
                        handleRadioChange(
                            event.favorite, event.eventId
                        )
                    }
                /></div>)
                const vs = "vs"
                const underdogPicked = (<div><input
                    type="radio"
                    id="underdog"
                    key={`${event.eventId}-underdog`}
                    value={event.underdog}
                    checked={
                        selection[event.eventId] === event.underdog
                    }
                    onChange={() =>
                        handleRadioChange(
                            event.underdog, event.eventId
                        )
                    }
                /></div>)
    
                const userPickObj = {
                    ...event,
                    underdogPicked: underdogPicked,
                    vs: vs,
                    favoritePicked: favoritePicked,
                    id: event.eventId
                }
    
                userPicksetData.currentWeek.push(userPickObj)
            }
            setIsSaveEnabled(saveEnabled)
            setUserPickset(userPicksetData)
        }
        
    }

    const weekClicked = (e) => {
        getPickset(e.target.value)
        setIsPickLoading(true)
    }

    const createWeeks = (numWeeks, isPost) => {
        const weekList = []
        const selected = {}
        for (let weekNumber = 1; weekNumber <= numWeeks; weekNumber++) {
            const isCurrentWeek = !isPost && currentWeek === weekNumber

            selected[weekNumber] = isCurrentWeek
            //weekList.push((<div key={weekNumber} onClick={weekClicked} className={`col PickemPicksPage-weeks ${isCurrentWeek ? "PickemPicksPage-selected": null}`}><p>{weekNumber}</p></div>))
            weekList.push((<button key={weekNumber} value={weekNumber} onClick={e => weekClicked(e, "value")} className={`PickemPicksPage-weekButton ${isCurrentWeek ? "PickemPicksPage-currentWeek": "PickemPicksPage-notCurrentWeek"}`}>{weekNumber}</button>))
        }
        return weekList
    }

    const handleSavePicks = () => {
        const userId = competitionData.picksUserId ? competitionData.picksUserId : user.uid
        const savePicksetObj = {
            competitionId: competitionId,
            currentWeek: currentWeek,
            pickData: selection,
            isRandomUser: competitionData.isRandomUser,
            userId: userId
        }
        setIsSavingPicks(true)
        functions.httpsCallable('pickemSavePickset')({
            body: JSON.stringify({...savePicksetObj})
        }).then((result) => {
            setIsSavingPicks(false)
            if (result.data.success) {
                console.log("Pickset Saved:", result.data)
            }
        }) 

    }

    const handleCancelPicks = () => {
        console.log("Canceling Picks")
    }

    const SaveButton = () => {
        return (
            <button style={{ fontSize: "1em", marginLeft: "5em" }}
            className={`btn-primary col-md-3 ${!isSaveEnabled ? "PickemPicksPage-disabled" : null}`} onClick={handleSavePicks} disabled={!isSaveEnabled}
        >
            Save
        </button>
        )
    }

    const CancelButton = () => {
       return ( <button style={{ fontSize: "1em", marginLeft: "5em" }}
            className={`btn-secondary col-md-3 ${!isSaveEnabled ? "PickemPicksPage-disabled" : null}`} onClick={handleCancelPicks} disabled={!isSaveEnabled}
        >
            Cancel
        </button>
       )
    }

    return (
        <PickemLayoutPage
            title="Picks"
            name={nameString} 
            competitionName={competitionName} 
            competitionDescription={competitionDescription}
            isDifferentUser={competitionData.picksUserId ? competitionData.picksUserId !== user.uid : false}
            subtitle={
                <>
                    {
                        <div style={{ display: "flex", fontSize: "1em", margin: 10 }}>
                            
                                <SaveButton />
                                <CancelButton />
                            
                        </div>
                    }
                </>
            }
        >
            <div className="PickemPicksPage">
            <div className="PickemPicksPage-weekView">
                {!isLoading && userPickset.regWeeks ? <div className="PickemPicksPage-weeks">
                    <div className="PickemPicksPage-weeks">
                        <div style={{margin: '0.25em'}}><p>Regular</p></div>
                        {
                            createWeeks(userPickset.regWeeks, false)
                        }
                    </div>
                </div> : null}
                {!isLoading && userPickset.postWeeks ? <div className="PickemPicksPage-weeks">
                
                    <div className="PickemPicksPage-weeks">
                        <div style={{padding: '0.1em'}}><p>Post</p></div>
                        {
                            createWeeks(userPickset.postWeeks, true)
                        }
                    </div>
                </div> : null}
            </div>
            {!isPickLoading && userPickset.currentWeek && !isSelectedLoading && !isSavingPicks ? 
                <div><PickemTable
                    subject="leagues"
                    headList={headList}
                    list={userPickset.currentWeek}
                    selected={[]}
                /> 
                    <div className="PickemPicksPage-saveButtons">
                    <SaveButton />
                    <CancelButton />
                    </div>
                </div>
                : !errorLoadingPicks ? <Spinner></Spinner> : <NoResults
                label={"Error retrieving pickset"}
            />}
                </div>
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemPicksPage);

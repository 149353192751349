import React, { useState, useContext } from "react";
import { PickemBlankPage } from "layouts/PickemBlankPage";
import { AuthContext } from "store/AuthProvider";
//import { Text} from "components/common/Form/TextInput/TextInput";
import "./SelectPathPage.scss";

const SelectPathPage = ({superPathSelect}) => {

    return (
        <PickemBlankPage>
            <div className="SelectPathPage">
                <div className="SelectPathPage-selectView">
                    <div className="SelectPathPage-optionBox">
                        <div className="SelectPathPage-logo">
                            <img style={{width: '150px'}} src="/images/icon.png" />
                        </div>
                            <button
                                onClick={() => superPathSelect("dashboard")}
                                type="submit"
                                value="dashboard"
                                className={`btn-primary SelectPathPage-btn`}
                                style={{ fontSize: "1em" }}
                            >
                                Dashboard
                            </button>
                    </div>
                    <div className="SelectPathPage-optionBox">
                        <div className="SelectPathPage-logo">
                            <img style={{width: '150px'}} src="/images/icon.png" />
                        </div>
                            <button
                                onClick={() => superPathSelect("pickem")}
                                type="submit"
                                value="pickem"
                                className={`btn-primary SelectPathPage-btn`}
                                style={{ fontSize: "1em" }}
                            >
                                Pickem
                            </button>
                    </div>
                    
                    
                </div>
            </div>
        </PickemBlankPage>
    );
};

export default SelectPathPage;

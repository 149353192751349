import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
//import { Breadcrumbs } from "components/common/Breadcrumbs";
//import { NoResults } from "components/common/NoResults";
//import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import Checkbox from "components/common/Form/Checkbox/Checkbox";
import { Select } from "components/common/Form/Select/Select";
//import { Select } from "components/common/Form/Select/Select";
import Confirmation from "modals/Confirmation";
import { simpleOptions } from "utils/helpers";
import "./PickemJoinPage.scss";
const CryptoJS = require("crypto-js");


const PickemJoinPage = ({ 
    history, 
    closeModal, 
    openModal,
    match: {
        params: {
            competitionId
        }
    }
 }) => {
    const { functions } = useContext(AuthContext);
    const { updateMeta, competitionData, updateCompetitionData } = useContext(StoreContext);
    const [compId, setCompId] = useState(competitionId)
    const [competitionPassword, setCompetitionPassword] = useState(null)
    const [picksetName, setPicksetName] = useState(null)
    const [showErrorModal, setShowErrorModal] = useState(false)

    useEffect(() => {
        updateMeta({
            title: "Join League",
        });
        console.log("Comp Data:", competitionData)
        setCompetitionPassword(competitionData.password)
    }, []);

    const confirm = (action) => {
        let message = '';
        switch (action) {
            case 'NoPicksetName':
                message = "Pickset Name Is Required"
                break
            case 'NoCompId':
                message = "Competition ID Is Required"
                break
            case 'PasswordNotMatch':
                message = "Password Does Not Match"
                break
            case 'LeagueNotExist':
                message = "League Not Found"
                break
            default:
                message = "An Error Has Occurred"
        }
        openModal({
            title: "Error",
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={false}
                    onConfirm={(e) => {
                        closeModal();
                    }}
                />
            ),
        });
    };

    const handleJoinLeague = () => {
        if (!compId) {
            confirm("NoCompId")
            return
        }
        if (!picksetName) {
            confirm("NoPicksetName")
            return
        }
        const leagueObject = {
            competitionId: compId,
            competitionPassword,
            picksetName
        }
        functions.httpsCallable("pickemCompetitionJoin")({...leagueObject}).then((result => {
            console.log("Result:", result)
            if (result.data.success) {
                history.push("/")
                updateCompetitionData({...competitionData, password: null})
            }
            else {
                confirm(result.data.message)
            }
        }))
    }

    return (
        <PickemLayoutPage
            title="Join League"
        >
            <div className="PickemJoinPage">
                <div className="col">
                    <p style={{margin: '0.5em'}}>League Join Information:</p>
                    <div className="PickemJoinPage-leagueinfo" style={{margin: '0.5em'}}>
                    <TextInput
                            inputData={{
                                title: "Enter a League ID",
                                placeholder: "League ID",
                                value: compId || "",
                                type: "text",
                            }}
                            code="name"
                            onChange={setCompId}
                        />
                    </div>
                    <div className="PickemJoinPage-leagueinfo" style={{margin: '0.5em'}}>
                    <TextInput
                        inputData={{
                            title: "Enter a League Password",
                            placeholder: "League Password",
                            value: competitionPassword || "",
                            type: "text",
                        }}
                        code="password"
                        onChange={setCompetitionPassword}
                    />
                    </div>
                    <div className="PickemJoinPage-leagueinfo" style={{margin: '0.5em'}}>
                    <TextInput
                        inputData={{
                            title: "Enter a Pickset Name",
                            placeholder: "Pickset Name",
                            value: picksetName || "",
                            type: "text",
                        }}
                        code="compId"
                        onChange={setPicksetName}
                    />
                    </div>

                    <div className="PickemJoinPage-leagueinfo" style={{margin: '0.5em'}}>
                        <button onClick={handleJoinLeague} style={{ fontSize: "1em" }}
                            className="btn-primary"
                        >
                            Join and Continue
                        </button>
                    </div>
                </div>
                
            </div>
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemJoinPage);

import React from "react";
//d="M 7.5,7.5 L 20,20 M 20,7.5 L 7.5,20" 
export const Exit = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 3,3 L 9,9 M 9,3 L 3,9" 
        stroke="white" 
        strokeWidth="1.5" 
        fillRule="evenodd"
        clipRule="evenodd"/>
    </svg>
  );
};



import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
import { StoreContext } from "store/StoreProvider";
import { AuthContext } from "store/AuthProvider";
import { PickemTable } from "components/common/Table/PickemTable";
import { headList } from "./model";
import Confirmation from "modals/Confirmation";
import { withDialog } from "components/common/withDialog/withDialog";
import "./PickemHomePage.scss";
import { Spinner } from "components/common/Spinner/Spinner";
import { Icon } from "components/common/Icons/Icon";

const PickemHomePage = ({history, location, openModal, closeModal}) => {
    const { updateMeta, competitionData, updateCompetitionData } = useContext(StoreContext);
    const { user, functions, accountData } = useContext(AuthContext);
    const [nameString, setNameString] = useState(null)
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionDescription, setCompetitionDescription] = useState(null)
    const [userCompetitions, setUserCompetitions] = useState(null)
    const [competitionTableData, setCompetitionTableData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isTableDataLoading, setIsTableDataLoading] = useState(true)

    useEffect(() => {
        updateMeta({
            title: "Home",
        });
        console.log("Starting Comp Data:", competitionData)
    }, []);

    const confirm = (action, compId, compName) => {
        let message = '';
        let title = ''
        switch (action) {
            case 'LeaveCompetition':
                title = `Leave ${compName}`
                message = "Do you want to leave this competition?"
                break
            case 'DeleteCompetition':
                title = `Delete ${compName}`
                message = "Do you want to delete this competition?"
                break
            default:
                title = "Error"
                message = "An Error Has Occurred"
                break
        }
        openModal({
            title: title,
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={true}
                    onConfirm={() => {
                        closeModal();
                            switch (action) {
                                case 'LeaveCompetition':
                                    leaveCompetition(compId, user.uid)
                                    break
                                case 'DeleteCompetition':
                                    deleteCompetition(compId)
                                    break
                            }
                    }}
                />
            ),
        });
    };

    useEffect(() => {
        const competitionData = []
        if (userCompetitions) {
            for (const competition of userCompetitions) {
                const competitionName = (
                    <Link to={{pathname: `/pickem-standings/${competition.competitionId}`}} onClick={() => updateCompetitionData({competitionName: competition.competitionName, competitionDescription: competition.competitionDescription, isCommissioner: competition.isCreator})} >
                        {competition.competitionName}
                    </Link>
                )
                let compTools = []
                let picksetName = ""
                console.log("User Info:", user)
                if (competition.picksetName) {
                    compTools.push(
                        <button onClick={() => handleButtonClick("LeaveCompetition", competition.competitionId, competition.competitionName)} className="btn-secondary PickemHomePage-toolsButton">
                            <Icon name={"close"} />
                        </button>
                    )
                    picksetName = (
                        <Link to={{pathname: `/pickem-picks/${competition.competitionId}`}} onClick={() => updateCompetitionData({competitionName: competition.competitionName, competitionDescription: competition.competitionDescription, isCommissioner: competition.isCreator, picksUserId: user.uid})}>
                            {competition.picksetName}
                        </Link>
                    )
                }
                else if (!competition.picksetName && competition.isCreator === user.uid) {
                    picksetName = (
                        <Link to={`/pickem-join/${competition.competitionId}`}>
                            <button style={{ marginLeft: ".5em" }}
                                    className="btn-primary"
                            >
                                Join
                            </button>
                        </Link>
                    )
                }

                
                
                if (competition.isCreator === user.uid) {

                    compTools.push(
                        <button onClick={() => handleButtonClick("EditCompetition", competition.competitionId, competition.competitionName, competition.competitionDescription, competition.isCreator)} className="btn-secondary PickemHomePage-toolsButton">
                            <Icon name={"edit"} />
                        </button>
                    )
                    compTools.push(
                        <button onClick={() => handleButtonClick("DeleteCompetition", competition.competitionId, competition.competitionName)} className="btn-secondary PickemHomePage-toolsButton">
                            <Icon name={"delete"} />
                        </button>
                    )
                    
                }
                
                const competitionTools = (
                    <div className="PickemHomePage-buttonview">
                        {compTools.map(t => t)}
                    </div> 
                    )
                const isCreator = competition.isCreator
                
                competitionData.push({
                    competitionName: competitionName,
                    picksetName: picksetName,
                    isCreator: isCreator,
                    competitionTools: competitionTools,
                    id: competition.competitionId
                })
            }
            setCompetitionTableData(() => competitionData)
            
        }
        setIsLoading(false)
    }, [userCompetitions])

    useEffect(() => {
        console.log("Comp Table Data:", competitionTableData)
        if (competitionTableData) {
            setIsTableDataLoading(false)
        }  
    }, [competitionTableData])

    const handleButtonClick = (action, compId, compName, compDescription, isCreator) => {
        console.log("Action:", action)
        if (action === "EditCompetition") {
            updateCompetitionData({competitionName: compName, competitionDescription: compDescription, isCommissioner: isCreator})
            history.push(`/pickem-settings/${compId}`)
        }
        else {
            confirm(action, compId, compName)
        }   
    }

    const leaveCompetition = (compId, userId) => {
        functions.httpsCallable('pickemCompetitionLeave')({
            competitionId: compId,
        }).then((result) => {
            console.log("Result of Leave:", result.data)
            if (result.data.success) {
                console.log("USer Info:", userId)
                console.log("Comp Id:", compId)
                //TODO: keep competition in list if is commissioner, ((c.competitionId !== compId && c.isCreator !== userId))
                const newCompetitionsList = userCompetitions.filter((c) => {
                    if (c.isCreator === userId)
                        return true
                    
                    if (c.competitionId !== compId)
                        return true

                    return false
                })
                setUserCompetitions(() => [...newCompetitionsList])
            }
            
        })
    }

    const getCompetitions = () => {
        functions.httpsCallable('pickemGetCompetitions')().then((result) => {
            console.log("Result:", result)
            if (result.data.success) {
                setUserCompetitions(() => [...result.data.data])
            }
        })
    }

    const deleteCompetition = (compId) => {
        functions.httpsCallable('pickemCompetitionDelete')({
            competitionId: compId,
        }).then((result) => {
            console.log("Result of Delete:", result.data)
            if (result.data.success) {
                const newCompetitionsList = userCompetitions.filter((c) => c.competitionId !== compId)
                setUserCompetitions(() => [...newCompetitionsList])
            }
            
        })
    }
    useEffect(() => {
        getCompetitions()
    }, [])

    useEffect(() => {
        let name = ""
        if (accountData.firstName && accountData.lastName) {
            name = `${accountData.firstName} ${accountData.lastName}`
        }
        else if (accountData.firstName) {
            name = `${accountData.firstName}`
        }
        else if (accountData.lastName) {
            name = `${accountData.lastName}`
        }
        else if (user.email) {
            name = `${user.email}`
        }

        console.log("Acccount Data:", accountData)
        console.log("User:", user)
        setNameString(name)
        setCompetitionDescription("")
        setCompetitionName("")
    }, [])

    return (
        <PickemLayoutPage title="Pickem Home" name={nameString} competitionName={competitionName} competitionDescription={competitionDescription}>
            <div className="PickemHomePage">
                <div className="PickemHomePage-leagueActionView">
                    <div style={{ display: "flex", fontSize: "1em" }}>
                        <Link to="/pickem-create">
                            <button style={{ fontSize: "1em", marginLeft: 0 }}
                                className="btn-primary"
                            >
                                Create League
                            </button>
                        </Link>
                        <Link to="/pickem-join">
                            <button style={{ fontSize: "1em", marginLeft: ".5em" }}
                                className="btn-primary"
                            >
                                Join League
                            </button>
                        </Link>
                        
                    </div>
                </div>
                {!isLoading && !isTableDataLoading ? <div><h1>Leagues</h1>
                    <PickemTable
                    subject="competitions"
                    headList={headList}
                    list={competitionTableData}
                    selected={[]}
                    />  </div> : <Spinner></Spinner>
                }
                
            </div>
        </PickemLayoutPage>
    );
};

export default withDialog(PickemHomePage);

export const menuItems = [
    { label: "Home", to: "", icon: "tasks", exact: true },
    { label: "Standings", to: "/pickem-standings", icon: "tasks"},
    { label: "Schedule", to: "/pickem-schedule", icon: "tasks"},
    { label: "Picks", to: "/pickem-picks", icon: "tasks"},
    { label: "Group", to: "/pickem-group-picks", icon: "tasks"},
    { label: "Settings", to: "/pickem-settings", icon: "tasks"},
    { label: "Account", to: "/pickem-account", icon: "account"},
    { label: "Rules", to: "/pickem-rules", icon: "tasks" },
];


// Home, Standings, Picks, Schedule, Group, Rules, Profile
// Home Screen - button for new league/competition, delete, list of leagues with trash icon to remove, edit leagues/competitions
// Competitions - fields listed in trello, drop down for league type, # of competitors
// Competitors - add competitor, click a league/compeitions show league details, delete/edit competitors
// Profile Screen: change some profile fields, list of your user v2 information, show competitions, 
// profile settings - no pick you can default to home, away, favorite, underdog, random
// Standings Page - columns needed for table: rank/seed, competitor name, pickset name, record (w-l), win %, picks (true - false - push - total), pick %, tiebreak, weekly finish, weekly total
// Need logic for: head to head schedule
// cron job for tournament table that builds a tournament sub table for every competition type (number of weeks, events, event ids)
// capture historic line info for events and write the score data here
// add a pickem page to dashboard, special cron job/function to rerun scores if needed
// Picks Page - all weeks across the top, rows = each game, 
// tournament - football (thurs - wed), baseball (mon - sun) lines: 1st, -6 hours, -2 hours, -1 hours, start/closing
